<template>
    <div class="row clearfix">
        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
            <div class="card">
                <!-- HEADER -->
                <div class="header">
                    <!--  -->
                    <div class="btn-group" role="group">
                        <button type="button" :class="{ 'bg-blue-grey': this.period_filter == 'm' }"
                            v-on:click="period_filtering('m')" class="btn waves-effect waves-light">CE
                            MOIS</button>
                        <button type="button" :class="{ 'bg-blue-grey': this.period_filter == 'p' }"
                            v-on:click="period_filtering('p')" class="btn waves-effect waves-light">PERSONNALISER</button>
                        <!-- 

                        <button type="button" class="btn bg-white waves-effect waves-light">-</button>

                         -->
                        <button type="button" :class="{ 'bg-black': this.toSend.filter == '0' }"
                            v-on:click="type_filtering('0')" class="btn waves-effect waves-light">EN ATTENTE DE
                            VALIDATION</button>
                        <button type="button" :class="{ 'bg-black': this.toSend.filter == '1' }"
                            v-on:click="type_filtering('1')" class="btn waves-effect waves-light">VALIDÉES</button>
                        <button type="button" :class="{ 'bg-black': this.toSend.filter == '2' }"
                            v-on:click="type_filtering('2')" class="btn waves-effect waves-light">REJETÉES</button>
                    </div>
                    <!--  -->
                    <ul v-show="period_filter == 'p'" class="header-dropdown m-r--5">
                        <input v-model="toSend.start" type="date" />
                        -
                        <input v-model="toSend.end" type="date" />
                        -
                        <button type="button" v-on:click="getData()"
                            class="btn btn-blue-grey btn-circle waves-effect waves-circle waves-float">
                            <i class="material-icons">autorenew</i>
                        </button>
                    </ul>
                </div>

                <!-- TABLE -->
                <div v-if="this.requests.length == 0" class="alert bg-black">
                    <center>Pas de données trouvées</center>
                </div>
                <div v-else class="body">
                    <div class="table-responsive" v-if="!table_load">
                        <table class="table table-bordered table-striped table-hover tableRequest">
                            <thead>
                                <tr>
                                    <th>Tag</th>
                                    <th>Date</th>
                                    <th>Employé</th>
                                    <th>Validations</th>
                                    <th>Motif</th>
                                    <th>Temps manqué</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="request in requests" :key=request.id>
                                    <td>
                                        <span v-if="request.exception.is_perm == 1"
                                            class="label label-default bg-pink">PERMISSION</span>
                                        <span v-else class="label label-default bg-red">JUSTIFICATION</span>
                                    </td>
                                    <td>{{ request.created_at.substring(0, 10) }}</td>
                                    <td> {{ request.exception.user.first_name }} <br> {{
                                        request.exception.user.last_name }}</td>
                                    <td>
                                        <span v-show="request.validation_sup == 1"
                                            class="label label-default bg-cyan">SUPERIEUR</span>
                                        <span v-show="request.validation_sup == 1 && request.validation_rh == 1"> - </span>
                                        <span v-show="request.validation_rh == 1"
                                            class="label label-default bg-teal">RH</span>
                                    </td>
                                    <td>
                                        <div class="panel">
                                            <div class="panel-heading" role="tab" :id="'headingTwo_' + request.id">
                                                <h4 class="panel-title">
                                                    <a class="collapsed" role="button" data-toggle="collapse"
                                                        data-parent="#accordion_1" :href="'#collapseTwo_' + request.id"
                                                        aria-expanded="false" aria-controls="collapseTwo_2">
                                                        {{ request.motif.text }}
                                                    </a>
                                                </h4>
                                            </div>
                                            <div :id="'collapseTwo_' + request.id" class="panel-collapse collapse"
                                                role="tabpanel" :aria-labelledby="'headingTwo_' + request.id">
                                                <div class="panel-body">
                                                    {{ request.other_motif }}
                                                </div>
                                            </div>
                                        </div>
                                    </td>
                                    <td>
                                        {{ request.exception.hour_issue.substring(0, 2) }}h {{
                                            request.exception.hour_issue.substring(3, 5) }}min
                                    </td>
                                    <td>
                                        <button v-show="request.state == '0' || request.state == '2'"
                                            v-on:click="validateRequest(request.id)" type="button"
                                            class="btn bg-green waves-effect">
                                            <i class="material-icons">check</i>
                                        </button>
                                        <span v-show="request.state == '0'"> - </span>
                                        <button v-show="request.state == '0' || request.state == '1'"
                                            v-on:click="rejectRequest(request.id)" type="button"
                                            class="btn bg-red waves-effect">
                                            <i class="material-icons">cancel</i>
                                        </button>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div style="display: flex; justify-content:center" v-else>
                        <div class="preloader pl-size-xs">
                            <div class="spinner-layer pl-indigo">
                                <div class="circle-clipper left">
                                    <div class="circle"></div>
                                </div>
                                <div class="circle-clipper right">
                                    <div class="circle"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import { initdatable } from '@/js/functions'
import Swal from 'sweetalert2'
import axios from 'axios'


export default {
    name: 'RequestTableComponent',
    components: {

    },
    data() {
        return {
            table_load: false,
            period_filter: 'm',
            type_filter: 'a',
            requests: [],
            toSend: { filter: '0', start: null, end: null },

        }
    },
    computed: {
        comp1() {
            return ""
        }
    },
    mounted() {
        this.getData()
    },
    created() {

    },
    methods: {

        async getData() {
            this.table_load = true
            await axios.post(this.$store.state.URL_API + 'getAllRequests', this.toSend)
                .then((res) => {
                    console.log(res.data)
                    this.requests = res.data.data
                    initdatable('.tableRequest')
                    this.table_load = false

                })
                .catch((error) => {
                    console.error(error)
                    this.table_load = false
                })
        },

        validateRequest(id) {

            Swal.fire({
                title: 'Valider la requête ?',
                text: "Vous pouvez modifier le statut plutard",
                icon: 'warning',
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Oui',
                cancelButtonText: 'Non',
                showCancelButton: true
            }).then((result) => {
                if (result.isConfirmed) {
                    axios.post(this.$store.state.URL_API + 'validateRequest/' + id)
                        .then(async (res) => {
                            // console.log(res.data)
                            if (res.data.status) {
                                await this.getData()
                                Swal.fire(
                                    'Succès !',
                                    'Requête validée avec succès',
                                    'success'
                                )
                            } else {
                                Swal.fire(
                                    'Oops..',
                                    res.data.message,
                                    'error'
                                )
                            }
                        })
                        .catch((error) => {
                            console.error(error)
                            Swal.fire(
                                'Oops..',
                                error.response.data.message,
                                'erroe'
                            )
                            this.table_load = false
                        })

                }
            })
        },

        async rejectRequest(id) {

            const { value: motif } = await Swal.fire({
                title: 'Pourquoi voulez-vous rejeter la requête ?',
                input: 'text',
                inputLabel: '',
                inputPlaceholder: 'motif de rejet..',
                inputAttributes: {
                    minlength: 5,
                    autocapitalize: 'off',
                    autocorrect: 'off'
                }

            })

            if (motif) {
                axios.post(this.$store.state.URL_API + 'rejectRequest/' + id, { reject_motif: motif })
                    .then(async (res) => {
                        console.log(res.data)
                        if (res.data.status) {
                            await this.getData()
                            Swal.fire(
                                'Succès !',
                                'Requête rejetée avec succès',
                                'success'
                            )
                        } else {
                            Swal.fire(
                                'Oops..',
                                res.data.message,
                                'error'
                            )
                        }
                    })
                    .catch((error) => {
                        console.error(error)
                        Swal.fire(
                            'Oops..',
                            error.response.data.message,
                            'erroe'
                        )
                        this.table_load = false
                    })
            }
        },

        period_filtering(fil) {
            if (fil != this.period_filter) {
                this.period_filter = fil
                if (fil == 'm') {
                    this.toSend.start = null
                    this.toSend.end = null
                    this.getData()
                }
            }
        },

        type_filtering(fil) {
            if (fil != this.type_filter) {
                this.toSend.filter = fil
                this.getData()
            }
        },
    }
}

</script>