<template>
    <section>

        <!-- Left Sidebar -->
        <aside id="leftsidebar" class="sidebar">
            <!-- User Info -->
            <div class="user-info">
                <div class="image">
                    <img src="assets/images/user.png" width="48" height="48" alt="User" />
                </div>
                <div class="info-container">
                    <div class="name" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">{{ auth.first_name
                    }} {{ auth.last_name }}</div>
                    <div class="email">{{ auth.email }}</div>
                    <div class="btn-group user-helper-dropdown">
                        <i class="material-icons" data-toggle="dropdown" aria-haspopup="true"
                            aria-expanded="true">keyboard_arrow_down</i>
                        <ul class="dropdown-menu pull-right">
                            <li><a class="hand" data-toggle="modal" data-target="#smallModal"><i
                                        class="material-icons">lock</i>Mot de
                                    passe</a></li>
                            <li><a class="hand" v-on:click="logout()"><i class="material-icons">input</i>Déconnexion</a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <!-- User Info -->
            <!-- Menu -->
            <div class="menu">
                <ul class="list">
                    <!-- NAVIGATION -->
                    <li class="header">NAVIGATION PRINCIPALE</li>
                    <li :class="{ active: selecteditem == 1 }">
                        <a v-on:click="selectitem(1)" class="hand waves-effect waves-black">
                            <!-- <i class="material-icons">home</i> -->
                            <span>Tableau de bord</span>
                        </a>
                    </li>
                    <!-- GESTION -->
                    <li class="header">GESTION</li>
                    <li :class="{ active: selecteditem == 2 }">
                        <a v-on:click="selectitem(2)" class="hand waves-effect waves-black">
                            <!-- <i class="material-icons">home</i> -->
                            <span>Régistre des présences</span>
                        </a>
                    </li>
                    <li :class="{ active: selecteditem == 3 }">
                        <a v-on:click="selectitem(3)" class="hand waves-effect waves-black">
                            <!-- <i class="material-icons">home</i> -->
                            <span>Régistre des exceptions</span>
                        </a>
                    </li>
                    <li :class="{ active: selecteditem == 4 }">
                        <a v-on:click="selectitem(4)" class="hand waves-effect waves-black">
                            <!-- <i class="material-icons">home</i> -->
                            <span>Régistre des requêtes</span>
                        </a>
                    </li>
                    <!-- PERSONNEL -->
                    <li class="header">PERSONNEL</li>
                    <li :class="{ active: selecteditem == 5 }">
                        <a v-on:click="selectitem(5)" class="hand waves-effect waves-black">
                            <!-- <i class="material-icons">home</i> -->
                            <span>Employés</span>
                        </a>
                    </li>
                    <li :class="{ active: selecteditem == 6 }">
                        <a v-on:click="selectitem(6)" class="hand waves-effect waves-black">
                            <!-- <i class="material-icons">home</i> -->
                            <span>Départements</span>
                        </a>
                    </li>
                    <!-- CONFIGURATION -->
                    <li class="header">CONFIGURATIONS</li>
                    <li :class="{ active: selecteditem == 7 }">
                        <a v-on:click="selectitem(7)" class="hand waves-effect waves-black">
                            <!-- <i class="material-icons">home</i> -->
                            <span>Zones autorisées</span>
                        </a>
                    </li>
                    <li :class="{ active: selecteditem == 8 }">
                        <a v-on:click="selectitem(8)" class="hand waves-effect waves-black">
                            <!-- <i class="material-icons">home</i> -->
                            <span>Jours autorisés</span>
                        </a>
                    </li>

                    <!-- <li>
                        <a href="javascript:void(0);" class="menu-toggle">
                            <i class="material-icons">widgets</i>
                            <span>Widgets</span>
                        </a>
                        <ul class="ml-menu">
                            <li>
                                <a href="javascript:void(0);" class="menu-toggle">
                                    <span>Cards</span>
                                </a>
                                <ul class="ml-menu">
                                    <li>
                                        <a href="pages/widgets/cards/basic.html">Basic</a>
                                    </li>
                                    <li>
                                        <a href="pages/widgets/cards/colored.html">Colored</a>
                                    </li>
                                    <li>
                                        <a href="pages/widgets/cards/no-header.html">No Header</a>
                                    </li>
                                </ul>
                            </li>
                            <li>
                                <a href="javascript:void(0);" class="menu-toggle">
                                    <span>Infobox</span>
                                </a>
                                <ul class="ml-menu">
                                    <li>
                                        <a href="pages/widgets/infobox/infobox-1.html">Infobox-1</a>
                                    </li>
                                    <li>
                                        <a href="pages/widgets/infobox/infobox-2.html">Infobox-2</a>
                                    </li>
                                    <li>
                                        <a href="pages/widgets/infobox/infobox-3.html">Infobox-3</a>
                                    </li>
                                    <li>
                                        <a href="pages/widgets/infobox/infobox-4.html">Infobox-4</a>
                                    </li>
                                    <li>
                                        <a href="pages/widgets/infobox/infobox-5.html">Infobox-5</a>
                                    </li>
                                </ul>
                            </li>
                        </ul>
                    </li> -->
                    <!-- <li>
                        <a href="javascript:void(0);">
                            <i class="material-icons col-red">donut_large</i>
                            <span>Important</span>
                        </a>
                    </li> -->
                </ul>
            </div>
            <!-- #Menu -->
            <!-- Footer -->
            <!-- <div class="legal">
                <div class="copyright">
                    &copy; 2023 <a href="https://lce-ci.com">La Locomotive</a>
                </div>
                <div class="version">
                    <b>Version: </b> 1.1.0
                </div>
            </div> -->
            <!-- #Footer -->
        </aside>

    </section>

    <div class="modal fade" id="smallModal" tabindex="-1" role="dialog">
        <div class="modal-dialog modal-sm" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h4 class="modal-title" id="smallModalLabel">Modifier mon mot de passe</h4>
                </div>
                <div class="modal-body">
                    <form @submit.prevent="updatePassword()">
                        <div class="form-line">
                            <input v-model="updatepassword.old" type="password" id="oldpass" class="form-control"
                                placeholder="Ancien mot de passe" required>
                        </div>
                        <br>
                        <div class="form-line">
                            <input v-model="updatepassword.new" type="password" id="newpass" class="form-control"
                                placeholder="Nouveau mot de passe" required>
                        </div>
                        <br>
                        <div class="form-line">
                            <input v-model="updatepassword.confirm" type="password" id="confirmpass" class="form-control"
                                placeholder="Confirmation nouveau mot de passe" required>
                        </div>
                        <div class="modal-footer">
                            <button type="submit" class="btn btn-primary waves-effect">VALIDER</button>
                            <button type="button" class="btn btn-danger waves-effect" data-dismiss="modal">X</button>
                        </div>

                    </form>
                </div>

            </div>
        </div>
    </div>
</template>

<script>
import Swal from 'sweetalert2'
import axios from 'axios'

export default {
    name: 'MainsideComponent',
    components: {

    },
    data() {
        return {
            auth: this.$store.state.auth_attendance,
            updatepassword: { old: '', new: '', confirm: '' },
            selecteditem: this.target
        }
    },
    props: {
        target: {
            required: false,
            default: 1
        }
    },
    computed: {
        comp() {
            return "computed"
        }
    },
    mounted() {
    },
    created() {

    },
    methods: {
        selectitem(value) {
            console.log(value)
            var data = this
            if (this.selecteditem != value) {
                this.selecteditem = value
                switch (value) {
                    case 1:
                        setTimeout(() => {
                            data.$router.push({ 'name': 'dashboard' })
                        }, 250);
                        break;
                    case 2:
                        setTimeout(() => {
                            data.$router.push({ 'name': 'presences' })
                        }, 250);
                        break;
                    case 3:
                        setTimeout(() => {
                            data.$router.push({ 'name': 'exceptions' })
                        }, 250);
                        break;
                    case 4:
                        setTimeout(() => {
                            data.$router.push({ 'name': 'requetes' })
                        }, 250);
                        break;
                    case 5:
                        setTimeout(() => {
                            data.$router.push({ 'name': 'employes' })
                        }, 250);
                        break;
                    case 6:
                        setTimeout(() => {
                            data.$router.push({ 'name': 'departements' })
                        }, 250);
                        break;
                    case 7:
                        setTimeout(() => {
                            data.$router.push({ 'name': 'zones' })
                        }, 250);
                        break;
                    case 8:
                        setTimeout(() => {
                            data.$router.push({ 'name': 'days' })
                        }, 250);
                        break;
                    default:
                        break;
                }
            }
        },

        logout() {
            axios.get(this.$store.state.URL_API + "auth/logout")
                .then((res) => {
                    console.log(res.data)
                    localStorage.setItem('token', "")
                    localStorage.setItem('auth_attendance', {})
                    localStorage.setItem('connected', false)

                    this.$store.state.token = ""
                    this.$store.state.auth_attendance = {}
                    this.$store.state.connected = false

                    this.$router.push({ name: 'signin' })
                })
                .catch((error) => {
                    console.error(error)
                })
        },

        async updatePassword() {
            if (this.updatepassword.new == this.updatepassword.confirm) {
                axios.post(this.$store.state.URL_API + "auth/updatePassword", { old_password: this.updatepassword.old, new_password: this.updatepassword.new })
                    .then((res) => {
                        if (res.data.status) {
                            this.updatepassword = { old: '', new: '', confirm: '' }
                            Swal.fire({
                                'title': 'Succès',
                                'text': 'Mot de passe modifié',
                                icon: 'success',
                            })
                        } else {
                            Swal.fire({
                                'title': 'Oops..',
                                'text': res.data.message,
                                icon: 'error',
                            })
                        }
                    })
                    .catch((error) => {
                        Swal.fire({
                            'title': 'Oops..',
                            'text': error.response.data.message,
                            icon: 'error',
                        })
                        console.error(error)
                    })
            } else {
                Swal.fire({
                    'title': 'Désolé',
                    'text': "Le mot de passe de confirmation ne correspond pas",
                    icon: 'error',
                })
            }

        }
    }
}

</script>

<style scopped>
.hand {
    cursor: pointer;
}
</style>