<template>
    <div class="row clearfix">
        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
            <div class="card">
                <div class="body">
                    <form @submit.prevent="addUser()">
                        <div class="row clearfix">
                            <div class="col-md-6">
                                <label for="lastname">Nom</label>
                                <div class="form-group">
                                    <div class="form-line">
                                        <input v-model="userdata.first_name" type="text" id="lastname" class="form-control"
                                            placeholder="Entrer le nom" required>
                                    </div>
                                </div>
                            </div>
                            <!--  -->
                            <div class="col-md-6">
                                <label for="firstname">Prénoms</label>
                                <div class="form-group">
                                    <div class="form-line">
                                        <input v-model="userdata.last_name" type="text" id="firstname" class="form-control"
                                            placeholder="Entrer le prénom" required>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!--  -->
                        <div class="row clearfix">
                            <div class="col-md-6">
                                <label for="email_address">Email</label>
                                <div class="form-group">
                                    <div class="form-line">
                                        <input v-model="userdata.email" type="email" id="email_address" class="form-control"
                                            placeholder="Entrer l'addresse email" required>
                                    </div>
                                </div>
                            </div>
                            <!--  -->
                            <div class="col-md-6">
                                <label for="phone">Numéro de téléphone</label>
                                <div class="form-group">
                                    <div class="form-line">
                                        <input v-model="userdata.phone" type="text" id="phone" class="form-control"
                                            placeholder="Entrer un numéro de téléphone" required>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!--  -->
                        <div class="row clearfix">
                            <div class="col-md-6">
                                <label for="poste">Département</label>
                                <div class="form-line">
                                    <select v-model="userdata.profile_id" id="poste" class="form-control" required>
                                        <option value="">-- Choisissez un département --</option>
                                        <option v-for="profile in profiles" :key="profile.id" :value="profile.id"> {{
                                            profile.label }} </option>
                                    </select>
                                </div>
                            </div>
                            <!--  -->
                            <div class="col-md-6">
                                <label for="salary">Salaire</label>
                                <div class="form-group">
                                    <div class="form-line">
                                        <input v-model="userdata.salary" type="money" id="salary" class="form-control"
                                            placeholder="Entrer le salaire de l'employé" required>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!--  -->
                        <div class="row clearfix">
                            <!--  -->
                            <div class="col-md-6">
                                <button type="submit" class="btn btn-primary m-t-15 waves-effect">AJOUTER +</button>
                            </div>
                        </div>
                        <br>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import axios from 'axios'
import Swal from 'sweetalert2'

export default {
    name: 'NewEmployeeComponent',
    components: {

    },
    data() {
        return {
            profiles: [],
            userdata: {
                first_name: null,
                last_name: null,
                email: null,
                phone: null,
                profile_id: "",
                salary: null
            }
        }
    },
    computed: {
        comp1() {
            return ""
        }
    },
    mounted() {
        this.getProfiles()
    },
    created() {

    },
    methods: {
        async getProfiles() {
            await axios.get(this.$store.state.URL_API + 'getAllProfiles')
                .then((res) => {
                    this.profiles = res.data.data
                })
                .catch((error) => {
                    console.error(error)
                })
        },

        async addUser() {
            Swal.fire({
                title: "Confirmer l'ajout de l'utilisateur",
                text: "",
                icon: 'warning',
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Je confirme !',
                cancelButtonText: "J'annule",
                showCancelButton: true
            }).then((result) => {
                if (result.isConfirmed) {
                    axios.post(this.$store.state.URL_API + 'auth/signup', this.userdata)
                        .then(async (res) => {
                            if (res.data.status) {
                                window.location.reload()
                                // await this.getData()
                                Swal.fire(
                                    'Succès !',
                                    'Utilisateur ajoutéF',
                                    'success'
                                )
                            } else {
                                Swal.fire(
                                    'Oops..',
                                    res.data.message,
                                    'error'
                                )
                            }
                        })
                        .catch((error) => {
                            console.error(error)
                        })
                }
            })

        }
    }
}

</script>