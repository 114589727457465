<template>
    <HeadComponent />
    <MainsideComponent target="7" />
    <section class="content">
        <div class="container-fluid">

            <div class="row clearfix">
                <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                    <div class="card">
                        <div class="header">
                            <form @submit.prevent="addZone()">
                                <div class="row clearfix">
                                    <div class="col-md-3">
                                        <div class="form-line">
                                            <input v-model="zonedata.label" type="text" class="form-control"
                                                placeholder="Libeéllé" required>
                                        </div>
                                    </div>
                                    <div class="col-md-3">
                                        <div class="form-line">
                                            <input v-model="zonedata.latitude" type="text" class="form-control"
                                                placeholder="Latitude" required>
                                        </div>
                                    </div>
                                    <div class="col-md-3">
                                        <div class="form-line">
                                            <input v-model="zonedata.longitude" type="text" class="form-control"
                                                placeholder="Longitude" required>
                                        </div>
                                    </div>
                                    <div class="col-md-3">
                                        <button type="submit" class="btn btn-primary waves-effect">Nouvelle zone autorisée
                                            +</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                        <!--  -->
                        <div class="body">
                            <input id="pac-input" class="controls" type="text" placeholder="Search Box" />
                            <div id="map" class="gmap"></div>
                        </div>
                    </div>
                </div>
            </div>

            <!--  -->

            <div class="row clearfix">
                <div class="col-lg-9 col-md-9 col-sm-9 col-xs-9">
                    <div class="card">
                        <!-- TABLE -->
                        <div class="body">
                            <div class="table-responsive" v-if="!table_load">
                                <table class="table table-bordered table-striped table-hover tableEmployee">
                                    <thead>
                                        <tr>
                                            <th>Libéllé</th>
                                            <th>Latitude</th>
                                            <th>Longitude</th>
                                            <th>Actions</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="zone in zones" :key="zone.id">
                                            <td>
                                                <div class="form-line">
                                                    <input v-model="zone.label" type="text" class="form-control"
                                                        placeholder="Libéllé">
                                                </div>
                                            </td>
                                            <td>
                                                <div class="form-line">
                                                    <input v-model="zone.latitude" type="text" class="form-control"
                                                        placeholder="Latitude">
                                                </div>
                                            </td>
                                            <td>
                                                <div class="form-line">
                                                    <input v-model="zone.longitude" type="text" class="form-control"
                                                        placeholder="Longitude">
                                                </div>
                                            </td>
                                            <td>
                                                <button type="button" class="btn bg-teal waves-effect" v-on:click="showplace(
                                                    zone.id,
                                                    {
                                                        lat: zone.latitude,
                                                        lng: zone.longitude
                                                    }, zone.users, zone.unusers)">
                                                    <i class="material-icons">remove_red_eye</i>
                                                </button>
                                                -
                                                <button
                                                    v-on:click="updateZone(zone.id, zone.label, zone.latitude, zone.longitude)"
                                                    type="button" class="btn bg-blue waves-effect">
                                                    <i class="material-icons">send</i>
                                                </button>
                                                -
                                                <button v-on:click="removeZone(zone.id)" type="button"
                                                    class="btn bg-red waves-effect">
                                                    <i class="material-icons">delete</i>
                                                </button>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div style="display: flex; justify-content:center" v-else>
                                <div class="preloader pl-size-xs">
                                    <div class="spinner-layer pl-indigo">
                                        <div class="circle-clipper left">
                                            <div class="circle"></div>
                                        </div>
                                        <div class="circle-clipper right">
                                            <div class="circle"></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <!--  -->

                <div class="col-lg-3 col-md-3 col-sm-9 col-xs-9">
                    <div class="card">
                        <div class="body">
                            <div v-if="employees != null" style="position:relative; overflow-y: auto; height: 200px;">
                                <div v-for="employee in employees" :key="employee.id" class="list-group-item bg-grey">
                                    <button v-on:click="dissociateUserToZone(employee.id)" type="button" class="close"
                                        aria-label="Close">
                                        <span aria-hidden="true">x</span>
                                    </button>
                                    {{ employee.first_name }} {{ employee.last_name }}
                                </div>
                                <div class="list-group-item">
                                    <select v-model="chosenEmployeeId" id="autorises" class="form-control">
                                        <option value="">-- Choisissez un employér --</option>
                                        <option v-for="employee in unemployees" :key="employee.id" :value="employee.id">
                                            {{ employee.first_name }} {{ employee.last_name }}
                                        </option>
                                    </select>
                                </div>
                                <button v-on:click="associateUserToZone()" type="button"
                                    class="list-group-item btn bg-blue-grey">
                                    Autorisé cet employé
                                </button>
                            </div>
                            <div v-else>
                                Cliquez sur <button type="button" class="btn bg-teal">
                                    <i class="material-icons">remove_red_eye</i>
                                </button> pour voir les employées qui sont affiliés à cette zone
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>


        <!--  -->
        <div class="page-loader-wrapper" v-if="loader">
            <div class="loader">
                <div class="preloader">
                    <div class="spinner-layer pl-indigo">
                        <div class="circle-clipper left">
                            <div class="circle"></div>
                        </div>
                        <div class="circle-clipper right">
                            <div class="circle"></div>
                        </div>
                    </div>
                </div>
                <p>Chargement...</p>
            </div>
        </div>
    </section>
</template>

<script>
import HeadComponent from '@/components/menu/HeadComponent'
import MainsideComponent from '@/components/menu/MainsideComponent'
const { Loader } = require('google-maps')

import Swal from 'sweetalert2'
import axios from 'axios'

export default {
    name: 'ZonesView',
    components: {
        HeadComponent,
        MainsideComponent,
    },
    data() {
        return {
            zonedata: { label: null, latitude: null, longitude: null },
            chosenEmployeeId: "",
            chosenZoneId: null,
            infowindow: null,
            unemployees: [],
            employees: null,
            loader: false,
            zones: [],
            map: null,
        }
    },
    computed: {
        comp1() {
            return ""
        }
    },
    async mounted() {
        await this.loadpage()
    },
    created() {

    },
    methods: {
        async loadpage() {
            this.loader = true
            await this.getZones()
            await this.initMap()
            this.loader = false
        },

        async getZones() {
            await axios.get(this.$store.state.URL_API + 'getAllZones')
                .then((res) => {
                    this.zones = res.data.data
                })
                .catch((error) => {
                    console.error(error)
                })
        },

        async initMap() {
            const maploader = new Loader('AIzaSyBMuuGMFqw-2vCIdTeF1MKM84Fv7HsfF0g',
                { libraries: ['places'] })
            const google = await maploader.load()
            if (this.map == null) {
                this.map = new google.maps.Map(document.getElementById('map'), {
                    center: { lat: this.zones[0].latitude, lng: this.zones[0].longitude },
                    zoom: 19,
                })
            }
            var infowindows = []
            this.zones.forEach(element => {
                const i = this.zones.indexOf(element)
                const marker = new google.maps.Marker({
                    position: new google.maps.LatLng(element.latitude, element.longitude),
                    title: element.label,
                    icon: {
                        url: 'https://cdn-icons-png.flaticon.com/128/11477/11477403.png',
                        scaledSize: new google.maps.Size(50, 50),
                        origin: new google.maps.Point(0, 0),
                        anchor: new google.maps.Point(0, 0)
                    },
                    map: this.map
                })

                infowindows[i] = new google.maps.InfoWindow({
                    content: "<p>" + marker.title + "</p>",
                })

                marker.addListener("click", () => {
                    infowindows[i].open(this.map, marker)
                })
            })

            this.map.addListener("click", (mapsMouseEvent) => {
                console.log(JSON.stringify(mapsMouseEvent.latLng.toJSON(), null, 2))
                this.zonedata.latitude = mapsMouseEvent.latLng.toJSON().lat
                this.zonedata.longitude = mapsMouseEvent.latLng.toJSON().lng
            })

            // Create the search box and link it to the UI element.
            const input = document.getElementById("pac-input");
            const searchBox = new google.maps.places.SearchBox(input);

            this.map.controls[google.maps.ControlPosition.TOP_LEFT].push(input);
            // Bias the SearchBox results towards current map's viewport.
            this.map.addListener("bounds_changed", () => {
                searchBox.setBounds(this.map.getBounds());
            });

            // Listen for the event fired when the user selects a prediction and retrieve
            // more details for that place.
            searchBox.addListener("places_changed", () => {
                const places = searchBox.getPlaces();

                if (places.length == 0) {
                    return;
                }

                // For each place, get the icon, name and location.
                const bounds = new google.maps.LatLngBounds();

                places.forEach((place) => {
                    if (!place.geometry || !place.geometry.location) {
                        console.log("Returned place contains no geometry");
                        return;
                    }

                    if (place.geometry.viewport) {
                        // Only geocodes have viewport.
                        bounds.union(place.geometry.viewport);
                    } else {
                        bounds.extend(place.geometry.location);
                    }
                });
                this.map.fitBounds(bounds);
            });


        },

        async updateZone(id, label, latitude, longitude) {
            Swal.fire({
                title: "Confirmer la modification",
                text: "",
                icon: 'warning',
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Je confirme !',
                cancelButtonText: "J'annule",
                showCancelButton: true
            }).then(async (result) => {
                if (result.isConfirmed) {
                    console.log({
                        label: label,
                        latitude: latitude,
                        longitude: longitude
                    })
                    await axios.post(this.$store.state.URL_API + 'updateZone/' + id,
                        {
                            label: label,
                            latitude: latitude,
                            longitude: longitude
                        })
                        .then(async (res) => {
                            if (res.data.status) {
                                await Swal.fire(
                                    {
                                        title: 'Succès !',
                                        text: 'Zone modifiée',
                                        icon: 'success',
                                    }
                                ).then(() => {
                                    window.location.reload()
                                })
                            } else {
                                Swal.fire(
                                    'Oops..',
                                    res.data.message,
                                    'error'
                                )
                            }
                        })
                        .catch((error) => {
                            Swal.fire(
                                'Oops..',
                                error.response.data.message,
                                'error'
                            )
                            console.error(error)
                        })
                }
            })

        },

        async removeZone(id) {
            Swal.fire({
                title: "Confirmer la suppression",
                text: "Cette action est ireversible",
                icon: 'warning',
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Je confirme !',
                cancelButtonText: "J'annule",
                showCancelButton: true
            }).then(async (result) => {
                if (result.isConfirmed) {
                    await axios.post(this.$store.state.URL_API + 'removeZone/' + id)
                        .then(async (res) => {
                            if (res.data.status) {
                                await Swal.fire(
                                    {
                                        title: 'Succès !',
                                        text: 'Zone supprimeé',
                                        icon: 'success',
                                    }
                                ).then(() => {
                                    window.location.reload()
                                })
                            } else {
                                Swal.fire(
                                    'Oops..',
                                    res.data.message,
                                    'error'
                                )
                            }
                        })
                        .catch((error) => {
                            console.error(error)
                            this.table_load = false
                        })
                }
            })

        },

        async addZone() {
            Swal.fire({
                title: "Confirmer l'ajout",
                text: "",
                icon: 'warning',
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Je confirme !',
                cancelButtonText: "J'annule",
                showCancelButton: true
            }).then((result) => {
                if (result.isConfirmed) {
                    axios.post(this.$store.state.URL_API + 'addZone', this.zonedata)
                        .then(async (res) => {
                            if (res.data.status) {
                                await Swal.fire(
                                    {
                                        title: 'Succès !',
                                        text: 'Zone ajoutée',
                                        icon: 'success',
                                    }
                                ).then(() => {
                                    window.location.reload()
                                })
                            } else {
                                Swal.fire(
                                    'Oops..',
                                    res.data.message,
                                    'error'
                                )
                            }
                        })
                        .catch((error) => {
                            console.error(error)
                            Swal.fire(
                                'Oops..',
                                error.response.data.message,
                                'error'
                            )
                        })
                }
            })
        },

        async associateUserToZone() {
            Swal.fire({
                title: "Confirmer l'action",
                text: "",
                icon: 'warning',
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Je confirme !',
                cancelButtonText: "J'annule",
                showCancelButton: true
            }).then((result) => {
                if (result.isConfirmed) {
                    axios.post(this.$store.state.URL_API + 'associateUserToZone/' + this.chosenZoneId, { user_id: this.chosenEmployeeId })
                        .then(async (res) => {
                            console.log(res.data)
                            if (res.data.status) {
                                Swal.fire(
                                    {
                                        title: 'Succès !',
                                        text: 'Employé autorisé pour cet emplacement',
                                        icon: 'success',
                                    }
                                ).then(() => {
                                    window.location.reload()
                                })
                            } else {
                                Swal.fire(
                                    'Oops..',
                                    res.data.message,
                                    'error'
                                )
                            }
                        })
                        .catch((error) => {
                            console.error(error)
                            this.table_load = false
                        })
                }
            })
        },

        async dissociateUserToZone(id) {
            Swal.fire({
                title: "Dissocier cet utilisateur de l'mplacement ?",
                text: "",
                icon: 'warning',
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Je confirme !',
                cancelButtonText: "J'annule",
                showCancelButton: true
            }).then((result) => {
                if (result.isConfirmed) {
                    axios.post(this.$store.state.URL_API + 'dissociateUserToZone/' + this.chosenZoneId, { user_id: id })
                        .then(async (res) => {
                            console.log(res.data)
                            if (res.data.status) {
                                Swal.fire(
                                    {
                                        title: 'Succès !',
                                        text: 'Action éffectuée',
                                        icon: 'success',
                                    }
                                ).then(() => {
                                    window.location.reload()
                                })
                            } else {
                                Swal.fire(
                                    'Oops..',
                                    res.data.message,
                                    'error'
                                )
                            }
                        })
                        .catch((error) => {
                            console.error(error)
                            this.table_load = false
                        })
                }
            })

        },

        showplace(id, coordinates, employees, unemployees) {
            this.chosenZoneId = id
            this.employees = employees
            this.unemployees = unemployees
            this.map.setCenter(coordinates);
        },

    }
}

</script>